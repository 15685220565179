<template>
  <div class="ClosedHoursData"/>
</template>

<script>
import { SET_DISABLED_DATES } from '../../actions'
import TimeSlotsApi from '@/api/TimeSlotsApi'

export default {
  name: 'closed-hours-data',
  created() {
    this.getClosedHours()
  },
  methods: {
    async getClosedHours() {
      const result = (await TimeSlotsApi.closedHours())
      const disabledDates = result.closedHours.map((closedHour) => [new Date(closedHour.startDateTime), new Date(closedHour.endDateTime)])
      this.$store.dispatch(SET_DISABLED_DATES, disabledDates)
    }
  }
}
</script>
